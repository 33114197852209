<script setup>
import {InputError} from "@/Components/Form/index.js";
import useAttributesWithDusk from "@/Utils/useAttributesWithDusk.js";
import {ref, watch} from "vue";

const props = defineProps({
    id: String,
    form: Object,
    label: String,
    disabled: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: Boolean,
        default: false
    }
});
defineOptions({
    inheritAttrs: false
});

const model = ref(props.modelValue);

const emits = defineEmits(['update:modelValue']);

watch(() => props.modelValue, (value) => {
    model.value = value;
});

watch(model, (value) => {
    emits('update:modelValue', value);
    props.form[props.id] = value;
});

</script>

<template>
    <div class="block" :dusk="$attrs.duskComponentId">
        <label class="flex items-center cursor-pointer">
            <input
                v-bind="useAttributesWithDusk($attrs)"
                type="checkbox"
                :disabled="disabled"
                @change="form?.clearErrors(id);form?.validate && form.validate(id)"
                v-model="model"
                class="rounded border-gray-300 text-purple-600 dark:text-purple-500 shadow-sm focus:ring-blue-500 dark:focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"/>
            <span :class="{'hover:cursor-not-allowed' : disabled}"
                  class="ms-2 text-md text-gray-600 dark:text-gray-300" v-if="label">{{ label }}</span>
            <slot/>
        </label>

        <InputError class="mt-2" :message="form?.errors[id]"/>
    </div>
</template>
